
import { Resources } from '@/core/models/metadata/resources';
import { Services } from '@/core/models/metadata/services';
import { inject } from 'vue';
import { defineComponent } from 'vue';
import { Title } from '@/core/models/metadata/title'
import { ErrorType } from '@/core/models/metadata/error';
import { ValidationRulesService } from '@/core/services/validationRulesService';
import { UpdateUserRequest } from '@/core/models/requests/updateUserRequest';
import { UpdateUserService } from '@/core/services/updateUserService';
import RadialSpinner from "@/components/shared/RadialSpinner.vue";
import ErrorsManager from "@/components/shared/ErrorsManager.vue";
import { JwtWrapper } from '@/core/services/jwtWrapper';

let updateUserService!: UpdateUserService;
export default defineComponent({
    data() {
        updateUserService = inject(Services.UpdateUser) as UpdateUserService;
        const validationRules = inject(Services.ValidationRules) as ValidationRulesService;
        const resources = inject(Services.Resources) as Resources;
        return {
            newUserTitle: JwtWrapper.jwtClaims.title ?? '',
            newUserFirstName: JwtWrapper.jwtClaims.firstName ?? '',
            newUserLastName: JwtWrapper.jwtClaims.lastName ?? '',
            buttonSave: resources.buttons.continue,
            changeYourName: resources.titles.changeYourName,
            loading: false,
            rules: validationRules.getValidationRules(),
            isValid: false,
            enterNewFirstNameLabel: resources.messages.firstName,
            enterNewLastNameLabel: resources.messages.lastName,
            enterTitleLabel: resources.messages.title,
            titles: Object.values(Title),
            errorType: ErrorType.UpdateFields,
            done: false,
            migratedUserMessage: resources.messages.migratedUserMessage,
            learnMore: resources.messages.learnMore,
            correctKeyDetails: resources.messages.correctKeyDetails,
        };
    },
    computed: {
        isDisabled() {
            return {
                "disabled-button": !this.isValid,
            };
        },
        vProps(): {
            variant: "outlined" | "filled" | "plain" | "underlined" | "solo" | "solo-inverted" | "solo-filled" | undefined,
            density: null | 'default' | 'comfortable' | 'compact' | undefined
        } {
            return {
                variant: "outlined",
                density: "compact",
            };
        }
    },
    methods: {
        async submitForm() {
            if (!this.isValid) return;
            this.loading = true;
            const updateUserNamesQuery: UpdateUserRequest = {
                title: this.newUserTitle,
                firstName: this.newUserFirstName,
                lastName: this.newUserLastName,
            };
            const response = await updateUserService.handle(
                updateUserNamesQuery
            );
            if (response.isSuccess) {
                this.done = true;
                localStorage.removeItem('jwt');
                window.location.href = JwtWrapper.getContinueEndpoint();
            }

            this.loading = false;
        },
    },
    components: {
        ErrorsManager,
        RadialSpinner
    }
})


import { Resources } from '@/core/models/metadata/resources';
import { Services } from '@/core/models/metadata/services';
import { inject } from 'vue';
import { defineComponent } from 'vue';
import { JwtWrapper } from "@/core/services/jwtWrapper";


export default defineComponent({
    data(){
        const resources = inject(Services.Resources) as Resources;
        return {
            emailVerifiedTitle: resources.titles.emailVerified,
            emailVerifiedMessage: resources.messages.emailVerified,
            emailVerifiedText: resources.buttons.emailVerified,
            title: JwtWrapper.jwtClaims?.title,
            firstName: JwtWrapper.jwtClaims?.firstName,
            lastName: JwtWrapper.jwtClaims?.lastName
        };
    },
    methods: {
        next(){
            if (!this.title && !this.firstName && !this.lastName) {
            this.$router.push('/userData');
            }
            else {
                window.location.href = JwtWrapper.navigateToUserProfile();
            }
        }
    }
})

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67df4942"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "componentTitle" }
const _hoisted_2 = { class: "card-text" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "card-text" }
const _hoisted_5 = { class: "card-text" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorsManager = _resolveComponent("ErrorsManager")!
  const _component_RadialSpinner = _resolveComponent("RadialSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.emailTitle), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.validationEmailSent), 1),
    _createElementVNode("p", {
      class: "card-text-email",
      title: _ctx.userEmail
    }, _toDisplayString(_ctx.userEmail), 9, _hoisted_3),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.clickTheLink), 1),
    _createElementVNode("p", _hoisted_5, [
      _createTextVNode(_toDisplayString(_ctx.verifyYourEmail) + " ", 1),
      _createElementVNode("span", null, [
        _createElementVNode("a", {
          href: "#",
          class: "email-verification-link",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendEmailVerificationRequest && _ctx.sendEmailVerificationRequest(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.emailText) + ". ", 1)
      ])
    ]),
    _createVNode(_component_ErrorsManager, { errorType: _ctx.errorType }, null, 8, ["errorType"]),
    _createElementVNode("div", null, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_RadialSpinner)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: "card-link-text back-button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigateBack()))
      }, _toDisplayString(_ctx.back), 1)
    ])
  ]))
}